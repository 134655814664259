import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { parse, stringify } from 'flatted';
import { Observable, Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { commonKeys } from '../models/constants';
import { DataModel, Entity, Field } from '../models/data-model.model';
import { State } from '../models/tasks.model';
import { User } from '../models/user.model';
import { UserRequest } from '../models/userRequest.model';
import { AccessControlResponse } from '../models/accessControleResponse.model';
import { ValidationApiKeyResponse } from '../models/validationApiKeyResponse.model';
import { ValidateApiKeyRequest } from '../models/validateApiKeyRequest.model';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private readonly _LOADER_MESSAGE_1: string = "Loading, please wait...";
    private readonly _LOADER_MESSAGE_2: string = "This is taking longer than usual, please be patient.";

    private loaderVisible: boolean;
    private loaderSubject: Subject<boolean>;
    private actualLoaderMessage: string | SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private environmentData: EnvironmentData
    ) {
        this.loaderVisible = false;
        this.loaderSubject = new Subject<boolean>();
        this.actualLoaderMessage = this._LOADER_MESSAGE_1;
    }

    getSubscription(): Observable<boolean> {
        return this.loaderSubject.asObservable();
    }

    show(customMessage?: string): void {
        this.loaderVisible = true;

        if (customMessage && customMessage.trim().length > 0) {
            this.actualLoaderMessage = this.sanitizer.bypassSecurityTrustHtml(customMessage);
        } else {
            this.actualLoaderMessage = this._LOADER_MESSAGE_1;
        }

        this.loaderSubject.next(true);

        setTimeout(() => {
            if (this.isLoaderVisible() && this.actualLoaderMessage == this._LOADER_MESSAGE_1) {
                this.actualLoaderMessage = this._LOADER_MESSAGE_2;
            }
        }, 9000);
    }

    hide(): void {
        this.loaderVisible = false;
        this.loaderSubject.next(false);
    }

    isLoaderVisible(): boolean {
        return Boolean(this.loaderVisible);
    }

    getLoaderMessage() {
        return this.actualLoaderMessage;
    }
}

@Injectable({
    providedIn: 'root'
})
export class FieldOnChangeService {
    private fieldChangedSubject: Subject<{ fieldName: string, inputSource: any, value: any, formGroup: FormGroup }>;

    constructor() {
        this.fieldChangedSubject = new Subject<{ fieldName: string, inputSource: any, value: any, formGroup: FormGroup }>();
    }

    getOnFieldChangeSubscription(): Observable<{ fieldName: string, inputSource: any, value: any, formGroup: FormGroup }> {
        return this.fieldChangedSubject.asObservable();
    }

    onChange(fieldName: string, value: any, formGroup: FormGroup): void {
        let payload = null;

        /**
         * Value is explicitely saved in 'value' field when 'inputSource' is passed
         * e.g. { 'field1': { 'inputSource: [], 'value': '' }
         * 
         * If 'inputSource' is not present, anything that is present against field is treated value
         * e.g. { 'field1': '' } or { 'field1': [] } or { 'field1': {} }
         */
        if (value && value['inputSource']) {
            payload = {
                'fieldName': fieldName,
                "inputSource": value["inputSource"],
                'value': value['value'],
                'formGroup': formGroup
            };
        } else {
            payload = {
                'fieldName': fieldName,
                'value': value,
                'formGroup': formGroup
            };
        }

        this.fieldChangedSubject.next(payload);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {
    stages: any = {};
    readOnly: boolean;
    entity: Entity;
    dataModelId: string;
    selectedTask: State;
    datamodelName: string;
    // Task Listing Separate
    listingSelectedTask

    sendStages(stages: any) {
        this.stages = stages;
    }

    getStages() {
        return this.stages;
    }

    setEntity(entity: Entity) {
        this.entity = entity;
    }

    setDataModelName(datamodelName: string) {
        this.datamodelName = datamodelName;
    }

    getDataModelName() {
        return this.datamodelName;
    }

    getEntity() {
        return this.entity;
    }

    setAssocEntityDetails(task: State) {
        this.selectedTask = task;
    }

    getAssocEntityDetails() {
        return this.selectedTask;
    }

    setSelectedDataModelId(dataModelId: string) {
        this.dataModelId = dataModelId;
    }

    getSelectedDataModelId() {
        return this.dataModelId;
    }

}

@Injectable({
    providedIn: 'root'
})
export class  UserAccessControlStorageService {
    private accessControl: AccessControlResponse;

    setAccessControl(accessControl: AccessControlResponse) {
        this.accessControl = accessControl;
    }

    getAccessControl() {
        return this.accessControl;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SecuredStorageService {
    public static readonly _AUTHORIZATION: string = 'Authorization';
    public static readonly _BEARER_TOKEN: string = 'bearerToken';
    public static readonly _GENERATE_TOKEN: string = 'generateToken';
    public static readonly _DATA_MODEL_NAME: string = 'dataModelName';
    public static readonly _COMPANY_ID: string = 'companyId';
    public static readonly _USER_INFO: string = 'userInfo';
    public static readonly _UNIVERSAL_USER: string = "universalUser";
    public static readonly _X_AUTH_TOKEN: string = "token";
    public static readonly _RENDER_UI: string = 'renderui';

    private storage: Map<string, any>;
    
    constructor() {
        this.storage = new Map();
    }

    setItem(key: string, value: any): void {
        this.storage.set(key, value);
    }

    getItem(key: string): any {
        return this.storage.get(key);
    }

    removeItem(key: string): void {
        this.storage.delete(key);
    }

    clearStorage(): void {
        this.storage.clear();
        localStorage.removeItem(SecuredStorageService._X_AUTH_TOKEN);
        localStorage.removeItem(SecuredStorageService._UNIVERSAL_USER);
    }

    setXAuthToken(token: string) {
        localStorage.setItem(SecuredStorageService._X_AUTH_TOKEN, token);
    }

    getXAuthToken() {
        return localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN);
    }

    setUniversalUser(user: User) {
        localStorage.setItem(SecuredStorageService._UNIVERSAL_USER, JSON.stringify(user));
    }

    getUniversalUser() {
        if (localStorage.getItem(SecuredStorageService._UNIVERSAL_USER) != null) {
            return JSON.parse(localStorage.getItem(SecuredStorageService._UNIVERSAL_USER));
        }
        return null;
        
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        private httpClient: HttpClient,
        private storageService: SecuredStorageService,
        private environmentData: EnvironmentData
    ) { }

    public getToken(): Observable<any> {
        const subject = new Subject<any>();

        const body = {
            "referenceKey": this.storageService.getItem(SecuredStorageService._DATA_MODEL_NAME),
            "referenceType": "DATA_MODEL",
            "companyId": this.storageService.getItem(SecuredStorageService._COMPANY_ID)
        };

        let anchor = document.createElement("a");
        let authDomain = document.referrer;
        if (!authDomain) {
            authDomain = window.parent.location.origin;
        }
        anchor.href = authDomain
        authDomain = anchor.protocol + "//" + anchor.host;
        body["authDomain"] = authDomain;

        let url = this.environmentData.getEnvData().baseURL + this.environmentData.getEnvData().tokenAuthorizer;

        this.httpClient.post<any>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body.success && response.body.result && response.body.result.authToken) {
                    this.storageService.setItem(SecuredStorageService._BEARER_TOKEN, response.body.result.authToken);
                    subject.next(response.body);
                } else if (!response.body.success && response.body.error.message) {
                    this.storageService.removeItem(SecuredStorageService._BEARER_TOKEN);
                    subject.error(response.body.error.message);
                } else {
                    this.storageService.removeItem(SecuredStorageService._BEARER_TOKEN);
                    subject.error("something went wrong");
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        )

        return subject.asObservable();
    }

    validateAuthToken(): Observable<boolean> {
        const subject: Subject<boolean> = new Subject<boolean>();

        if (!this.loginRequired()) {
            setTimeout(() => {
                subject.next(true);
            }, 100);
        }
        else if (this.storageService.getItem(SecuredStorageService._GENERATE_TOKEN)) {
            const bearerToken = this.storageService.getItem(SecuredStorageService._BEARER_TOKEN);

            if (bearerToken && this.isBearerTokenValid(bearerToken)) {
                setTimeout(() => {
                    subject.next(true);
                }, 100);
            } else {
                this.getToken().subscribe(
                    (bearerToken: string) => {
                        subject.next(true);
                    },
                    (error: any) => {
                        subject.next(false);
                    }
                );
            }
        } else if (this.storageService.getItem(SecuredStorageService._BEARER_TOKEN)) {
            setTimeout(() => {
                subject.next(true);
            }, 100);
        } else {
            // Fallback for any other case
            setTimeout(() => {
                subject.next(true);
            }, 100);
        }

        return subject.asObservable();
    }

    private isBearerTokenValid(token: string): boolean {
        if (token && token.trim().length > 0) {
            try {
                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                const jwtData = JSON.parse(jsonPayload);

                // Checking expiry 5 mins before the actual expiry
                return jwtData && jwtData['exp'] && (((jwtData['exp'] * 1000) - (5 * 1000 * 60)) > new Date().getTime());
            } catch (err) {
                return false;
            }
        }

        return false;
    }

    validateApiKey(apiKey: string): Observable<ValidationApiKeyResponse> {
        const subject = new Subject<ValidationApiKeyResponse>();
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey";
        const validateApiKeyRequest: ValidateApiKeyRequest = new ValidateApiKeyRequest();
        validateApiKeyRequest.apiKey = apiKey;
        this.httpClient.post<ValidationApiKeyResponse>(
          url,
          validateApiKeyRequest,
          {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
          }
        ).subscribe(
          (response: HttpResponse<ValidationApiKeyResponse>) => {
            subject.next(response.body)
          },
          (err: HttpErrorResponse) => {
            subject.error(err);
          }
        );
        return subject.asObservable();
    }
    
    authenticate(validateApiKeyRequest: ValidateApiKeyRequest): Observable<User> {
        const subject = new Subject<User>();
        validateApiKeyRequest.sendOtp = false;
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey/auth";
        this.httpClient.post<User>(
          url,
          validateApiKeyRequest,
          {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
          }
        ).subscribe(
          (response: HttpResponse<User>) => {
            if (response.headers != null && response.headers.get("x-auth-token") != null) {
                this.storageService.setXAuthToken(response.headers.get("x-auth-token") || "");
                if (response.body.authorities && response.body.authorities.includes("ROLE_EXTERNAL_USER")) {
                    this.environmentData.getEnvData().flowAPI = "/flow/externaluser/";
                }
                else {
                    this.environmentData.getEnvData().flowAPI = "/flow/console/";
                }
                this.storageService.setUniversalUser(response.body);
            }
            subject.next(response.body)
          },
          (err: HttpErrorResponse) => {
            subject.error(err);
          }
        );
        return subject.asObservable();
    }
    
    sendOTP(validateApiKeyRequest: ValidateApiKeyRequest): Observable<ValidationApiKeyResponse> {
        const subject = new Subject<ValidationApiKeyResponse>();
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey";
        validateApiKeyRequest.sendOtp = true;
        this.httpClient.post<ValidationApiKeyResponse>(
          url,
          validateApiKeyRequest,
          {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            subject.next(response.body);
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
    
            subject.error(err);
          }
        );
        return subject.asObservable();
    }

    setBasePathFromUser(user: User) {
        if (this.environmentData.getEnvData()) {
            if (user.authorities && user.authorities.includes("ROLE_EXTERNAL_USER")) {
                this.environmentData.getEnvData().flowAPI = "/flow/externaluser/";
            }
            else {
                this.environmentData.getEnvData().flowAPI = "/flow/console/";
            }
        }
    }

    setBearerToken(token: string) {
        this.storageService.setItem(SecuredStorageService._BEARER_TOKEN, token);
    }

    loginRequired() {
        if (this.storageService.getXAuthToken() != null && this.storageService.getUniversalUser() != null) {
            this.setBasePathFromUser(this.storageService.getUniversalUser());
            return false;
        }
        if (this.storageService.getItem(SecuredStorageService._BEARER_TOKEN) != null) {
            return false;
        }
        return true;
    }

    logOut() {
        this.storageService.clearStorage();
    }
    getCompanyDetails(companyId): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + "/flow/ui/getLogoByCompanyId/"+ companyId ;
        this.httpClient.get(url, {responseType: 'text'}).subscribe(
            (response) => {
                if (response) {
                    subject.next(response);
                }
            },
            (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error

                subject.error(err);
            }
        );

        return subject.asObservable();
    }
}

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private loaderService: LoaderService,
        private environmentData: EnvironmentData
    ) { }

    upload(formData: FormData): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.fileUploadUrl;

        if (formData) {
            this.loaderService.show();
            this.authService.validateAuthToken().subscribe(
                (isValidToken: boolean) => {
                    this.httpClient.post<any>(
                        url,
                        formData,
                        {
                            observe: 'response',
                            reportProgress: true,
                            withCredentials: true
                        }
                    ).subscribe(
                        (response: HttpResponse<any>) => {
                            if (response.body) {
                                this.loaderService.hide();

                                subject.next(response.body);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            this.loaderService.hide();
                            subject.error(err);
                        }
                    );
                }
            );
        } else {
            subject.error('File is null or empty');
        }

        return subject.asObservable();
    }

    decodeURL(encodedString) {
        if (encodedString && typeof encodedString == "string") {
          var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
          var translate = {
            "nbsp": " ",
            "amp": "&",
            "quot": "\"",
            "lt": "<",
            "gt": ">"
          };
          return encodedString.replace(translate_re, function (match, entity) {
            return translate[entity];
          }).replace(/&#(\d+);/gi, function (match, numStr) {
            var num = parseInt(numStr, 10);
            return String.fromCharCode(num);
          });
        }
        return encodedString;
    }

    downloadMultipleFiles(files: any): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const { v4: uuidv4 } = require('uuid');
        const zipFileName = uuidv4();
        let payload = {"downloadFiles":files,"functionInstanceName":"API"};
        const url = envData.baseURL + "/api/downloadData";
        this.loaderService.show();
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.post<any>(
                    url,
                    payload,
                    {
                        observe: 'response',
                        reportProgress: true,
                        responseType: "blob" as "json"
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            this.loaderService.hide();
                            FileSaver.saveAs(response.body, zipFileName+".zip");
                                
                            }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        this.loaderService.hide();
                    }
                );
            }
        );
        return subject.asObservable();
    }

    download(filePath: string, isList?:boolean, getAsFileUrl?: boolean): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI.substring(0, envData.flowAPI.length - 1) + this.decodeURL(filePath);

        this.loaderService.show();
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<any>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        responseType: "blob" as "json"
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            this.loaderService.hide();

                            if (getAsFileUrl) {
                                const reader = new FileReader();
                                reader.addEventListener("load", () => {
                                    subject.next(reader.result);
                                }, false);

                                reader.readAsDataURL(response.body);
                            } else {
                                let urlParts = filePath.split("/");
                                let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
                                fileName = this.decodeURL(fileName);
                                if (isList == true) {
                                    FileSaver.saveAs(response.body, fileName+".zip");
                                }else{
                                    FileSaver.saveAs(response.body, fileName);
                                }
                            }
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        this.loaderService.hide();
                    }
                );
            }
        );

        return subject.asObservable();
    }

    delete(fileUrl: string, vimeoId?: string): Observable<any> {
        const subject = new Subject<any>();

        if (fileUrl.startsWith("/api/downloadData")) {
            fileUrl = fileUrl.replace("/api/downloadData", "");
        }
        const envData = this.environmentData.getEnvData();
        let url = envData.baseURL + envData.flowAPI + envData.deleteDataFile + fileUrl;

        if (vimeoId) {
            url = `${url}/${vimeoId}`;
        }

        this.loaderService.show();
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.delete<any>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            this.loaderService.hide();

                            subject.next(response.body);
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        this.loaderService.hide();
                    }
                );
            }
        );

        return subject.asObservable();
    }

    view(filePath: string, getAsFileUrl?: boolean): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + filePath;

        this.loaderService.show();
        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.get<any>(
                    url,
                    {
                        observe: 'response',
                        reportProgress: true,
                        responseType: "blob" as "json"
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            subject.next(response.body);

                            this.loaderService.hide();
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        this.loaderService.hide();
                    }
                );
            }
        );

        return subject.asObservable();
    }
    filePDFtoImage(formData: any): Observable<any> {
      const subject = new Subject<any>();
      const envData = this.environmentData.getEnvData();
      const url = envData.baseURL +  envData.pdfToImage;
      if (formData) {
        this.httpClient.post<any>(
          url,
          formData,
          {
            withCredentials: true,
            observe: 'response',
            reportProgress: true,
            responseType: "blob" as "json"
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );
      } else {
        subject.error('File is null or empty');
      }
  
      return subject.asObservable();
    }
}

@Injectable({
    providedIn: 'root'
})
export class EntitySharingService {
    listCallInProcess: boolean = false;
    entityMap: any = {};
    validationErrorMsgs: string;

    readonly _SYSTEM_FIELDS: string[] = [
        '_id', 'companyId', '_entityName', 'datamodelId', 'stageCd', 'statusCd', 'customerId', 'flowId', 'machineType',
        'createdAt', 'createdBy', 'createdByEmail', 'updatedAt', 'updatedBy', 'updatedByEmail', 'updateByMachineType',
        'lastKnownPage', 'pageFinished', 'pageProgress', '_carouselFinished', '_carouselLastKnownIndex', '_carouselProgress',
        '_lastKnownVideoDuration', '_piExtractResponseIds', '_videoFinished', '_linkageId', '_parentDataModelName', '_parentId', '_documentId'
    ];

    constructor(private snackBar: MatSnackBar) {
    }

    clearAll() {
        this.listCallInProcess = null;
        this.entityMap = null;
        this.validationErrorMsgs = null;
    }

    getEntityMap(fields: Field[], entityMap?: any) {
        const tempFields: Field[] = parse(stringify(fields));

        if (entityMap) {
            this.entityMap = Object.assign({}, entityMap);
        } else {
            this.entityMap = {};
        }

        for (let field of tempFields) {
            this.createMap(field);
        }
        return this.entityMap;
    }

    createMap(field: Field, parentField?: Field, listCall?: boolean) {
        if (field.type == commonKeys.entityLookupReference && field.value) {
            field.value = field["actualValue"];
        }
        if ((field.type == "DATE" || field.type == "DATETIME") && field.value) {
            if (field.value instanceof Date) {
                field.value = field.value.toISOString();
            } else {
                field.value = new Date(field.value).toISOString();
            }
        }
        if (field.type == "MODEL" && !field.list) {
            if (field.value) {
                for (let f of field.value.fields) {
                    let map = {}
                    if (field["map"] != undefined) {
                        map = field["map"];
                    }
                    if (f.type == "MODEL" && !f.list) {
                        this.createMap(f, field);
                    }
                    else if (f.type == "MODEL" && f.list) {
                        //this.createMap(f,field);
                        let mapArr = [];
                        if (f.value && f.value.length > 0) {
                            this.listCallInProcess = true;
                            for (let value of f.value) {
                                let map = {};
                                for (let f1 of value.fields) {
                                    if (field != undefined) {
                                        map[f.name] = this.createMap(f1, f);
                                    }
                                }
                                mapArr.push(f["map"]);
                                f["map"] = {};
                            }
                            if (parentField != null || parentField != undefined) {
                                parentField["map"][f.name] = mapArr;
                            }
                            else {
                                this.listCallInProcess = false;
                                field["map"][f.name] = mapArr;
                                this.entityMap[field.name] = field["map"];
                            }
                            return;
                        }
                    }
                    else if ((f.type == "DATE" || f.type == "DATETIME") && f.value) {
                        if (f.value instanceof Date) {
                            f.value = f.value.toISOString();
                        } else {
                            f.value = new Date(f.value).toISOString();
                        }

                        map[f.name] = f.value;
                    }
                    else {
                        map[f.name] = this.getFieldValue(f);
                    }
                    field["map"] = map;
                }
                if (parentField != null || parentField != undefined) {
                    if (parentField["map"] == undefined) {
                        parentField["map"] = {}
                    }
                    parentField["map"][field.name] = field["map"];
                    return;
                }
                else {
                    if (!this.listCallInProcess) {

                        this.entityMap[field.name] = field["map"];
                        return;
                    }
                    else {
                        return field["map"];
                    }
                }
            }
        }
        if (field.type == "MODEL" && field.list) {
            let mapArr = [];
            if (field && field.value && field.value.length > 0) {
                this.listCallInProcess = true;
                for (let value of field.value) {
                    let map = {};
                    if (this.isIterable(value.fields)) {
                        for (let f of value.fields) {
                            if (field != undefined) {
                                map[f.name] = this.createMap(f, field);
                            }
                            //parent field is jobs
                        }
                        mapArr.push(field["map"]);
                        field["map"] = {};
                    }

                }
                if (parentField != null || parentField != undefined) {
                    parentField["map"][field.name] = mapArr;
                }
                else {
                    this.listCallInProcess = false;
                    this.entityMap[field.name] = mapArr;
                }
                return;
            }
        }
        else {
            if (!this.listCallInProcess) {
                if (this.getFieldValue(field) != undefined) {
                    this.entityMap[field.name] = this.getFieldValue(field);
                }
            }
            else {
                let map = parentField && parentField["map"] ? parentField["map"] : undefined;
                if (map == undefined) {
                    map = {}
                    parentField["map"] = map;
                }
                if (map[field.name] == undefined || map[field.name].length == 0) {
                    if (this.getFieldValue(field) != undefined) {
                        map[field.name] = this.getFieldValue(field);
                    }
                    parentField["map"] = map;
                }
            }
        }
    }

    getFieldValue(field: Field) {
        if (field.value != null && field.value != undefined) {
            return field.value;
        }
    }

    getPayloadToEvaluateMVEL(selectedDataModel: DataModel, viewEntity: any, state: State) {
        let mvelPayloadMap: any = {};
        mvelPayloadMap = this.getEntityMap(selectedDataModel.fields, mvelPayloadMap);

        if (viewEntity) {
            for (const systemField of this._SYSTEM_FIELDS) {
                if (viewEntity[systemField]) {
                    mvelPayloadMap[systemField] = viewEntity[systemField];
                }
            }
        }

        return {
            'payload': mvelPayloadMap,
            'state': state
        };
    }

    checkValidation(selectedDataModel: DataModel) {
        let validationArr = [];
        this.validationErrorMsgs = "";
        for (let field of selectedDataModel.fields) {
            if (field != undefined && field.validationExpression != null && field.validationExpression != undefined) {
                let validationResult = this.validateRegex(field);
                if (validationResult == false) {
                    validationArr.push(validationResult);
                }
            }
        }
        return validationArr;
    }

    validateRegex(field: Field) {
        if (field != undefined && field.validationExpression != undefined && field.validationExpression && field.validationExpression.length > 0 && (field.value instanceof String || typeof field.value === 'string')) {
            if (field.value != null && field.value != undefined) {
                const matchValues = field.value.match(field.validationExpression);
                if (!matchValues || matchValues.length == 0) {
                    var errorMsg = "Invalid input for field :: " + field.name;
                    if (field.errorMessage) {
                        errorMsg = field.errorMessage;
                        this.validationErrorMsgs = this.validationErrorMsgs.concat(errorMsg) + ' ,';
                    }
                    var allValidationErrorMsgs = this.validationErrorMsgs.substring(0, this.validationErrorMsgs.length - 1)
                    this.snackBar.open(allValidationErrorMsgs, "Dismiss", {
                        duration: 5000
                    })
                    return false;
                }
                else {
                    var regexExp = new RegExp(field.validationExpression, "g");
                    if (regexExp) {
                        var matches = regexExp.exec(<string>field.value);
                        if (matches && matches.length > 0 && field.value && field.value.length > 0 && matches[0] != null && matches[0].length > 0) {
                            field.value = matches[0];
                            return field;
                        }
                    }
                }
            }
            else {
                if (field.mandatory) {
                    var errorMsg = "Invalid input for field :: " + field.name;
                    if (field.errorMessage) {
                        errorMsg = field.errorMessage;
                    }
                    this.snackBar.open(errorMsg, "Dismiss", {
                        duration: 5000
                    })
                    return false;
                }
            }
        }
    }
    isIterable(input) {  
        if (input === null || input === undefined) {
          return false
        }
      
        return typeof input[Symbol.iterator] === 'function'
      }
}

@Injectable({
    providedIn: 'root'
})
export class ApiResponseQueue {
    private apiResponseQueue: any[] = [];

    constructor() { }

    push(apiResponse: any) {
        if (!this.apiResponseQueue) {
            this.apiResponseQueue = [];
        }

        this.apiResponseQueue.push(apiResponse);
    }

    pop() {
        if (this.apiResponseQueue && this.apiResponseQueue.length > 0) {
            return this.apiResponseQueue.shift();
        }

        return null;
    }

    hasMore(): boolean {
        return this.apiResponseQueue && this.apiResponseQueue.length > 0;
    }
}
@Injectable({
    providedIn: 'root'
})
export class DataSharingService {
    sharedObject: any;
    transientContext:any;
    roadBlockSHown: boolean = false;
    piextractMeta: any[] = [];
    piExtractResponse: any[] = [];
    clientLogo:any = "assets/images/automatapi-logo.svg";
    setSharedObject(sharedObject: any) {
        this.sharedObject = sharedObject;
    }

    getSharedObject() {
        let tempObject = null;
        if (this.sharedObject) {
            tempObject = JSON.parse(JSON.stringify(this.sharedObject));
            this.sharedObject = null;
        }
        return tempObject;
    }
}



export enum Status {
    INPROGRESS = "INPROGRESS",
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    DELETED = "DELETED"
}
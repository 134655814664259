import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { parse, stringify } from 'flatted';
import { Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { commonKeys } from '../models/constants';
import { ValidateApiKeyRequest } from '../models/validateApiKeyRequest.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "./environments.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/material/snack-bar";
export class LoaderService {
    constructor(sanitizer, environmentData) {
        this.sanitizer = sanitizer;
        this.environmentData = environmentData;
        this._LOADER_MESSAGE_1 = "Loading, please wait...";
        this._LOADER_MESSAGE_2 = "This is taking longer than usual, please be patient.";
        this.loaderVisible = false;
        this.loaderSubject = new Subject();
        this.actualLoaderMessage = this._LOADER_MESSAGE_1;
    }
    getSubscription() {
        return this.loaderSubject.asObservable();
    }
    show(customMessage) {
        this.loaderVisible = true;
        if (customMessage && customMessage.trim().length > 0) {
            this.actualLoaderMessage = this.sanitizer.bypassSecurityTrustHtml(customMessage);
        }
        else {
            this.actualLoaderMessage = this._LOADER_MESSAGE_1;
        }
        this.loaderSubject.next(true);
        setTimeout(() => {
            if (this.isLoaderVisible() && this.actualLoaderMessage == this._LOADER_MESSAGE_1) {
                this.actualLoaderMessage = this._LOADER_MESSAGE_2;
            }
        }, 9000);
    }
    hide() {
        this.loaderVisible = false;
        this.loaderSubject.next(false);
    }
    isLoaderVisible() {
        return Boolean(this.loaderVisible);
    }
    getLoaderMessage() {
        return this.actualLoaderMessage;
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.DomSanitizer), i0.ɵɵinject(i2.EnvironmentData)); }, token: LoaderService, providedIn: "root" });
export class FieldOnChangeService {
    constructor() {
        this.fieldChangedSubject = new Subject();
    }
    getOnFieldChangeSubscription() {
        return this.fieldChangedSubject.asObservable();
    }
    onChange(fieldName, value, formGroup) {
        let payload = null;
        /**
         * Value is explicitely saved in 'value' field when 'inputSource' is passed
         * e.g. { 'field1': { 'inputSource: [], 'value': '' }
         *
         * If 'inputSource' is not present, anything that is present against field is treated value
         * e.g. { 'field1': '' } or { 'field1': [] } or { 'field1': {} }
         */
        if (value && value['inputSource']) {
            payload = {
                'fieldName': fieldName,
                "inputSource": value["inputSource"],
                'value': value['value'],
                'formGroup': formGroup
            };
        }
        else {
            payload = {
                'fieldName': fieldName,
                'value': value,
                'formGroup': formGroup
            };
        }
        this.fieldChangedSubject.next(payload);
    }
}
FieldOnChangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldOnChangeService_Factory() { return new FieldOnChangeService(); }, token: FieldOnChangeService, providedIn: "root" });
export class CommunicationService {
    constructor() {
        this.stages = {};
    }
    sendStages(stages) {
        this.stages = stages;
    }
    getStages() {
        return this.stages;
    }
    setEntity(entity) {
        this.entity = entity;
    }
    setDataModelName(datamodelName) {
        this.datamodelName = datamodelName;
    }
    getDataModelName() {
        return this.datamodelName;
    }
    getEntity() {
        return this.entity;
    }
    setAssocEntityDetails(task) {
        this.selectedTask = task;
    }
    getAssocEntityDetails() {
        return this.selectedTask;
    }
    setSelectedDataModelId(dataModelId) {
        this.dataModelId = dataModelId;
    }
    getSelectedDataModelId() {
        return this.dataModelId;
    }
}
CommunicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommunicationService_Factory() { return new CommunicationService(); }, token: CommunicationService, providedIn: "root" });
export class UserAccessControlStorageService {
    setAccessControl(accessControl) {
        this.accessControl = accessControl;
    }
    getAccessControl() {
        return this.accessControl;
    }
}
UserAccessControlStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAccessControlStorageService_Factory() { return new UserAccessControlStorageService(); }, token: UserAccessControlStorageService, providedIn: "root" });
export class SecuredStorageService {
    constructor() {
        this.storage = new Map();
    }
    setItem(key, value) {
        this.storage.set(key, value);
    }
    getItem(key) {
        return this.storage.get(key);
    }
    removeItem(key) {
        this.storage.delete(key);
    }
    clearStorage() {
        this.storage.clear();
        localStorage.removeItem(SecuredStorageService._X_AUTH_TOKEN);
        localStorage.removeItem(SecuredStorageService._UNIVERSAL_USER);
    }
    setXAuthToken(token) {
        localStorage.setItem(SecuredStorageService._X_AUTH_TOKEN, token);
    }
    getXAuthToken() {
        return localStorage.getItem(SecuredStorageService._X_AUTH_TOKEN);
    }
    setUniversalUser(user) {
        localStorage.setItem(SecuredStorageService._UNIVERSAL_USER, JSON.stringify(user));
    }
    getUniversalUser() {
        if (localStorage.getItem(SecuredStorageService._UNIVERSAL_USER) != null) {
            return JSON.parse(localStorage.getItem(SecuredStorageService._UNIVERSAL_USER));
        }
        return null;
    }
}
SecuredStorageService._AUTHORIZATION = 'Authorization';
SecuredStorageService._BEARER_TOKEN = 'bearerToken';
SecuredStorageService._GENERATE_TOKEN = 'generateToken';
SecuredStorageService._DATA_MODEL_NAME = 'dataModelName';
SecuredStorageService._COMPANY_ID = 'companyId';
SecuredStorageService._USER_INFO = 'userInfo';
SecuredStorageService._UNIVERSAL_USER = "universalUser";
SecuredStorageService._X_AUTH_TOKEN = "token";
SecuredStorageService._RENDER_UI = 'renderui';
SecuredStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SecuredStorageService_Factory() { return new SecuredStorageService(); }, token: SecuredStorageService, providedIn: "root" });
export class AuthService {
    constructor(httpClient, storageService, environmentData) {
        this.httpClient = httpClient;
        this.storageService = storageService;
        this.environmentData = environmentData;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    getToken() {
        const subject = new Subject();
        const body = {
            "referenceKey": this.storageService.getItem(SecuredStorageService._DATA_MODEL_NAME),
            "referenceType": "DATA_MODEL",
            "companyId": this.storageService.getItem(SecuredStorageService._COMPANY_ID)
        };
        let anchor = document.createElement("a");
        let authDomain = document.referrer;
        if (!authDomain) {
            authDomain = window.parent.location.origin;
        }
        anchor.href = authDomain;
        authDomain = anchor.protocol + "//" + anchor.host;
        body["authDomain"] = authDomain;
        let url = this.environmentData.getEnvData().baseURL + this.environmentData.getEnvData().tokenAuthorizer;
        this.httpClient.post(url, body, {
            headers: this.httpHeaders,
            observe: 'response',
            reportProgress: true
        }).subscribe((response) => {
            if (response.body.success && response.body.result && response.body.result.authToken) {
                this.storageService.setItem(SecuredStorageService._BEARER_TOKEN, response.body.result.authToken);
                subject.next(response.body);
            }
            else if (!response.body.success && response.body.error.message) {
                this.storageService.removeItem(SecuredStorageService._BEARER_TOKEN);
                subject.error(response.body.error.message);
            }
            else {
                this.storageService.removeItem(SecuredStorageService._BEARER_TOKEN);
                subject.error("something went wrong");
            }
        }, (err) => {
            subject.error(err);
        });
        return subject.asObservable();
    }
    validateAuthToken() {
        const subject = new Subject();
        if (!this.loginRequired()) {
            setTimeout(() => {
                subject.next(true);
            }, 100);
        }
        else if (this.storageService.getItem(SecuredStorageService._GENERATE_TOKEN)) {
            const bearerToken = this.storageService.getItem(SecuredStorageService._BEARER_TOKEN);
            if (bearerToken && this.isBearerTokenValid(bearerToken)) {
                setTimeout(() => {
                    subject.next(true);
                }, 100);
            }
            else {
                this.getToken().subscribe((bearerToken) => {
                    subject.next(true);
                }, (error) => {
                    subject.next(false);
                });
            }
        }
        else if (this.storageService.getItem(SecuredStorageService._BEARER_TOKEN)) {
            setTimeout(() => {
                subject.next(true);
            }, 100);
        }
        else {
            // Fallback for any other case
            setTimeout(() => {
                subject.next(true);
            }, 100);
        }
        return subject.asObservable();
    }
    isBearerTokenValid(token) {
        if (token && token.trim().length > 0) {
            try {
                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                const jwtData = JSON.parse(jsonPayload);
                // Checking expiry 5 mins before the actual expiry
                return jwtData && jwtData['exp'] && (((jwtData['exp'] * 1000) - (5 * 1000 * 60)) > new Date().getTime());
            }
            catch (err) {
                return false;
            }
        }
        return false;
    }
    validateApiKey(apiKey) {
        const subject = new Subject();
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey";
        const validateApiKeyRequest = new ValidateApiKeyRequest();
        validateApiKeyRequest.apiKey = apiKey;
        this.httpClient.post(url, validateApiKeyRequest, {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
        }).subscribe((response) => {
            subject.next(response.body);
        }, (err) => {
            subject.error(err);
        });
        return subject.asObservable();
    }
    authenticate(validateApiKeyRequest) {
        const subject = new Subject();
        validateApiKeyRequest.sendOtp = false;
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey/auth";
        this.httpClient.post(url, validateApiKeyRequest, {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
        }).subscribe((response) => {
            if (response.headers != null && response.headers.get("x-auth-token") != null) {
                this.storageService.setXAuthToken(response.headers.get("x-auth-token") || "");
                if (response.body.authorities && response.body.authorities.includes("ROLE_EXTERNAL_USER")) {
                    this.environmentData.getEnvData().flowAPI = "/flow/externaluser/";
                }
                else {
                    this.environmentData.getEnvData().flowAPI = "/flow/console/";
                }
                this.storageService.setUniversalUser(response.body);
            }
            subject.next(response.body);
        }, (err) => {
            subject.error(err);
        });
        return subject.asObservable();
    }
    sendOTP(validateApiKeyRequest) {
        const subject = new Subject();
        const url = this.environmentData.getEnvData().baseURL + "/flow/validateApiKey";
        validateApiKeyRequest.sendOtp = true;
        this.httpClient.post(url, validateApiKeyRequest, {
            withCredentials: true,
            observe: 'response',
            reportProgress: true
        }).subscribe((response) => {
            subject.next(response.body);
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
    setBasePathFromUser(user) {
        if (this.environmentData.getEnvData()) {
            if (user.authorities && user.authorities.includes("ROLE_EXTERNAL_USER")) {
                this.environmentData.getEnvData().flowAPI = "/flow/externaluser/";
            }
            else {
                this.environmentData.getEnvData().flowAPI = "/flow/console/";
            }
        }
    }
    setBearerToken(token) {
        this.storageService.setItem(SecuredStorageService._BEARER_TOKEN, token);
    }
    loginRequired() {
        if (this.storageService.getXAuthToken() != null && this.storageService.getUniversalUser() != null) {
            this.setBasePathFromUser(this.storageService.getUniversalUser());
            return false;
        }
        if (this.storageService.getItem(SecuredStorageService._BEARER_TOKEN) != null) {
            return false;
        }
        return true;
    }
    logOut() {
        this.storageService.clearStorage();
    }
    getCompanyDetails(companyId) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + "/flow/ui/getLogoByCompanyId/" + companyId;
        this.httpClient.get(url, { responseType: 'text' }).subscribe((response) => {
            if (response) {
                subject.next(response);
            }
        }, (err) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
            subject.error(err);
        });
        return subject.asObservable();
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(SecuredStorageService), i0.ɵɵinject(i2.EnvironmentData)); }, token: AuthService, providedIn: "root" });
export class FileService {
    constructor(authService, httpClient, loaderService, environmentData) {
        this.authService = authService;
        this.httpClient = httpClient;
        this.loaderService = loaderService;
        this.environmentData = environmentData;
    }
    upload(formData) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.fileUploadUrl;
        if (formData) {
            this.loaderService.show();
            this.authService.validateAuthToken().subscribe((isValidToken) => {
                this.httpClient.post(url, formData, {
                    observe: 'response',
                    reportProgress: true,
                    withCredentials: true
                }).subscribe((response) => {
                    if (response.body) {
                        this.loaderService.hide();
                        subject.next(response.body);
                    }
                }, (err) => {
                    // All errors are handled in ErrorInterceptor, no further handling required
                    // Unless any specific action is to be taken on some error
                    this.loaderService.hide();
                    subject.error(err);
                });
            });
        }
        else {
            subject.error('File is null or empty');
        }
        return subject.asObservable();
    }
    decodeURL(encodedString) {
        if (encodedString && typeof encodedString == "string") {
            var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
            var translate = {
                "nbsp": " ",
                "amp": "&",
                "quot": "\"",
                "lt": "<",
                "gt": ">"
            };
            return encodedString.replace(translate_re, function (match, entity) {
                return translate[entity];
            }).replace(/&#(\d+);/gi, function (match, numStr) {
                var num = parseInt(numStr, 10);
                return String.fromCharCode(num);
            });
        }
        return encodedString;
    }
    downloadMultipleFiles(files) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const { v4: uuidv4 } = require('uuid');
        const zipFileName = uuidv4();
        let payload = { "downloadFiles": files, "functionInstanceName": "API" };
        const url = envData.baseURL + "/api/downloadData";
        this.loaderService.show();
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.post(url, payload, {
                observe: 'response',
                reportProgress: true,
                responseType: "blob"
            }).subscribe((response) => {
                if (response.body) {
                    this.loaderService.hide();
                    FileSaver.saveAs(response.body, zipFileName + ".zip");
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                this.loaderService.hide();
            });
        });
        return subject.asObservable();
    }
    download(filePath, isList, getAsFileUrl) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI.substring(0, envData.flowAPI.length - 1) + this.decodeURL(filePath);
        this.loaderService.show();
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                responseType: "blob"
            }).subscribe((response) => {
                if (response.body) {
                    this.loaderService.hide();
                    if (getAsFileUrl) {
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                            subject.next(reader.result);
                        }, false);
                        reader.readAsDataURL(response.body);
                    }
                    else {
                        let urlParts = filePath.split("/");
                        let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
                        fileName = this.decodeURL(fileName);
                        if (isList == true) {
                            FileSaver.saveAs(response.body, fileName + ".zip");
                        }
                        else {
                            FileSaver.saveAs(response.body, fileName);
                        }
                    }
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                this.loaderService.hide();
            });
        });
        return subject.asObservable();
    }
    delete(fileUrl, vimeoId) {
        const subject = new Subject();
        if (fileUrl.startsWith("/api/downloadData")) {
            fileUrl = fileUrl.replace("/api/downloadData", "");
        }
        const envData = this.environmentData.getEnvData();
        let url = envData.baseURL + envData.flowAPI + envData.deleteDataFile + fileUrl;
        if (vimeoId) {
            url = `${url}/${vimeoId}`;
        }
        this.loaderService.show();
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.delete(url, {
                observe: 'response',
                reportProgress: true,
            }).subscribe((response) => {
                if (response.body) {
                    this.loaderService.hide();
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                this.loaderService.hide();
            });
        });
        return subject.asObservable();
    }
    view(filePath, getAsFileUrl) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + filePath;
        this.loaderService.show();
        this.authService.validateAuthToken().subscribe((isValidToken) => {
            this.httpClient.get(url, {
                observe: 'response',
                reportProgress: true,
                responseType: "blob"
            }).subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                    this.loaderService.hide();
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                this.loaderService.hide();
            });
        });
        return subject.asObservable();
    }
    filePDFtoImage(formData) {
        const subject = new Subject();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.pdfToImage;
        if (formData) {
            this.httpClient.post(url, formData, {
                withCredentials: true,
                observe: 'response',
                reportProgress: true,
                responseType: "blob"
            }).subscribe((response) => {
                if (response.body) {
                    subject.next(response.body);
                }
            }, (err) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
                subject.error(err);
            });
        }
        else {
            subject.error('File is null or empty');
        }
        return subject.asObservable();
    }
}
FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(AuthService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(LoaderService), i0.ɵɵinject(i2.EnvironmentData)); }, token: FileService, providedIn: "root" });
export class EntitySharingService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.listCallInProcess = false;
        this.entityMap = {};
        this._SYSTEM_FIELDS = [
            '_id', 'companyId', '_entityName', 'datamodelId', 'stageCd', 'statusCd', 'customerId', 'flowId', 'machineType',
            'createdAt', 'createdBy', 'createdByEmail', 'updatedAt', 'updatedBy', 'updatedByEmail', 'updateByMachineType',
            'lastKnownPage', 'pageFinished', 'pageProgress', '_carouselFinished', '_carouselLastKnownIndex', '_carouselProgress',
            '_lastKnownVideoDuration', '_piExtractResponseIds', '_videoFinished', '_linkageId', '_parentDataModelName', '_parentId', '_documentId'
        ];
    }
    clearAll() {
        this.listCallInProcess = null;
        this.entityMap = null;
        this.validationErrorMsgs = null;
    }
    getEntityMap(fields, entityMap) {
        const tempFields = parse(stringify(fields));
        if (entityMap) {
            this.entityMap = Object.assign({}, entityMap);
        }
        else {
            this.entityMap = {};
        }
        for (let field of tempFields) {
            this.createMap(field);
        }
        return this.entityMap;
    }
    createMap(field, parentField, listCall) {
        if (field.type == commonKeys.entityLookupReference && field.value) {
            field.value = field["actualValue"];
        }
        if ((field.type == "DATE" || field.type == "DATETIME") && field.value) {
            if (field.value instanceof Date) {
                field.value = field.value.toISOString();
            }
            else {
                field.value = new Date(field.value).toISOString();
            }
        }
        if (field.type == "MODEL" && !field.list) {
            if (field.value) {
                for (let f of field.value.fields) {
                    let map = {};
                    if (field["map"] != undefined) {
                        map = field["map"];
                    }
                    if (f.type == "MODEL" && !f.list) {
                        this.createMap(f, field);
                    }
                    else if (f.type == "MODEL" && f.list) {
                        //this.createMap(f,field);
                        let mapArr = [];
                        if (f.value && f.value.length > 0) {
                            this.listCallInProcess = true;
                            for (let value of f.value) {
                                let map = {};
                                for (let f1 of value.fields) {
                                    if (field != undefined) {
                                        map[f.name] = this.createMap(f1, f);
                                    }
                                }
                                mapArr.push(f["map"]);
                                f["map"] = {};
                            }
                            if (parentField != null || parentField != undefined) {
                                parentField["map"][f.name] = mapArr;
                            }
                            else {
                                this.listCallInProcess = false;
                                field["map"][f.name] = mapArr;
                                this.entityMap[field.name] = field["map"];
                            }
                            return;
                        }
                    }
                    else if ((f.type == "DATE" || f.type == "DATETIME") && f.value) {
                        if (f.value instanceof Date) {
                            f.value = f.value.toISOString();
                        }
                        else {
                            f.value = new Date(f.value).toISOString();
                        }
                        map[f.name] = f.value;
                    }
                    else {
                        map[f.name] = this.getFieldValue(f);
                    }
                    field["map"] = map;
                }
                if (parentField != null || parentField != undefined) {
                    if (parentField["map"] == undefined) {
                        parentField["map"] = {};
                    }
                    parentField["map"][field.name] = field["map"];
                    return;
                }
                else {
                    if (!this.listCallInProcess) {
                        this.entityMap[field.name] = field["map"];
                        return;
                    }
                    else {
                        return field["map"];
                    }
                }
            }
        }
        if (field.type == "MODEL" && field.list) {
            let mapArr = [];
            if (field && field.value && field.value.length > 0) {
                this.listCallInProcess = true;
                for (let value of field.value) {
                    let map = {};
                    if (this.isIterable(value.fields)) {
                        for (let f of value.fields) {
                            if (field != undefined) {
                                map[f.name] = this.createMap(f, field);
                            }
                            //parent field is jobs
                        }
                        mapArr.push(field["map"]);
                        field["map"] = {};
                    }
                }
                if (parentField != null || parentField != undefined) {
                    parentField["map"][field.name] = mapArr;
                }
                else {
                    this.listCallInProcess = false;
                    this.entityMap[field.name] = mapArr;
                }
                return;
            }
        }
        else {
            if (!this.listCallInProcess) {
                if (this.getFieldValue(field) != undefined) {
                    this.entityMap[field.name] = this.getFieldValue(field);
                }
            }
            else {
                let map = parentField && parentField["map"] ? parentField["map"] : undefined;
                if (map == undefined) {
                    map = {};
                    parentField["map"] = map;
                }
                if (map[field.name] == undefined || map[field.name].length == 0) {
                    if (this.getFieldValue(field) != undefined) {
                        map[field.name] = this.getFieldValue(field);
                    }
                    parentField["map"] = map;
                }
            }
        }
    }
    getFieldValue(field) {
        if (field.value != null && field.value != undefined) {
            return field.value;
        }
    }
    getPayloadToEvaluateMVEL(selectedDataModel, viewEntity, state) {
        let mvelPayloadMap = {};
        mvelPayloadMap = this.getEntityMap(selectedDataModel.fields, mvelPayloadMap);
        if (viewEntity) {
            for (const systemField of this._SYSTEM_FIELDS) {
                if (viewEntity[systemField]) {
                    mvelPayloadMap[systemField] = viewEntity[systemField];
                }
            }
        }
        return {
            'payload': mvelPayloadMap,
            'state': state
        };
    }
    checkValidation(selectedDataModel) {
        let validationArr = [];
        this.validationErrorMsgs = "";
        for (let field of selectedDataModel.fields) {
            if (field != undefined && field.validationExpression != null && field.validationExpression != undefined) {
                let validationResult = this.validateRegex(field);
                if (validationResult == false) {
                    validationArr.push(validationResult);
                }
            }
        }
        return validationArr;
    }
    validateRegex(field) {
        if (field != undefined && field.validationExpression != undefined && field.validationExpression && field.validationExpression.length > 0 && (field.value instanceof String || typeof field.value === 'string')) {
            if (field.value != null && field.value != undefined) {
                const matchValues = field.value.match(field.validationExpression);
                if (!matchValues || matchValues.length == 0) {
                    var errorMsg = "Invalid input for field :: " + field.name;
                    if (field.errorMessage) {
                        errorMsg = field.errorMessage;
                        this.validationErrorMsgs = this.validationErrorMsgs.concat(errorMsg) + ' ,';
                    }
                    var allValidationErrorMsgs = this.validationErrorMsgs.substring(0, this.validationErrorMsgs.length - 1);
                    this.snackBar.open(allValidationErrorMsgs, "Dismiss", {
                        duration: 5000
                    });
                    return false;
                }
                else {
                    var regexExp = new RegExp(field.validationExpression, "g");
                    if (regexExp) {
                        var matches = regexExp.exec(field.value);
                        if (matches && matches.length > 0 && field.value && field.value.length > 0 && matches[0] != null && matches[0].length > 0) {
                            field.value = matches[0];
                            return field;
                        }
                    }
                }
            }
            else {
                if (field.mandatory) {
                    var errorMsg = "Invalid input for field :: " + field.name;
                    if (field.errorMessage) {
                        errorMsg = field.errorMessage;
                    }
                    this.snackBar.open(errorMsg, "Dismiss", {
                        duration: 5000
                    });
                    return false;
                }
            }
        }
    }
    isIterable(input) {
        if (input === null || input === undefined) {
            return false;
        }
        return typeof input[Symbol.iterator] === 'function';
    }
}
EntitySharingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntitySharingService_Factory() { return new EntitySharingService(i0.ɵɵinject(i4.MatSnackBar)); }, token: EntitySharingService, providedIn: "root" });
export class ApiResponseQueue {
    constructor() {
        this.apiResponseQueue = [];
    }
    push(apiResponse) {
        if (!this.apiResponseQueue) {
            this.apiResponseQueue = [];
        }
        this.apiResponseQueue.push(apiResponse);
    }
    pop() {
        if (this.apiResponseQueue && this.apiResponseQueue.length > 0) {
            return this.apiResponseQueue.shift();
        }
        return null;
    }
    hasMore() {
        return this.apiResponseQueue && this.apiResponseQueue.length > 0;
    }
}
ApiResponseQueue.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiResponseQueue_Factory() { return new ApiResponseQueue(); }, token: ApiResponseQueue, providedIn: "root" });
export class DataSharingService {
    constructor() {
        this.roadBlockSHown = false;
        this.piextractMeta = [];
        this.piExtractResponse = [];
        this.clientLogo = "assets/images/automatapi-logo.svg";
    }
    setSharedObject(sharedObject) {
        this.sharedObject = sharedObject;
    }
    getSharedObject() {
        let tempObject = null;
        if (this.sharedObject) {
            tempObject = JSON.parse(JSON.stringify(this.sharedObject));
            this.sharedObject = null;
        }
        return tempObject;
    }
}
DataSharingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataSharingService_Factory() { return new DataSharingService(); }, token: DataSharingService, providedIn: "root" });
export var Status;
(function (Status) {
    Status["INPROGRESS"] = "INPROGRESS";
    Status["DRAFT"] = "DRAFT";
    Status["ACTIVE"] = "ACTIVE";
    Status["DELETED"] = "DELETED";
})(Status || (Status = {}));

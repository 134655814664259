import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { EnvironmentData } from 'src/app/services/environments.service';
import { FieldActions } from '../models/constants';
import { DataModel, Field } from '../models/data-model.model';
import { AuthService, FieldOnChangeService } from './shared.service';
import { DataModelService } from './data-model.service';

@Injectable({
    providedIn: 'root'
})
export class APIService {
    public static readonly _IS_FORM_VALIDATION_NEEDED: string = '_isFormValidationNeeded';

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    private dataModelId: string;
    private entityId: string;
    private dataModel: DataModel;
    private dataModelName: string;
    private entity: any;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private toastrService: ToastrService,
        private fieldOnChangeService: FieldOnChangeService,
        private environmentData: EnvironmentData,
        protected dataModelService: DataModelService
    ) { }

    clearAll() {
        this.dataModelId = null;
        this.entityId = null;
    }

    setDataModel(dataModel: DataModel) {
        this.dataModel = dataModel;
        this.dataModelName = dataModel.name;
    }
    getDataModel() {
        return this.dataModel;
    }

    setDataModelId(dataModelId: string) {
        this.dataModelId = dataModelId;
    }

    setEntityId(entityId: string) {
        this.entityId = entityId;
    }
    setEntity(entity: any) {
        this.entity = entity;
    }
    getEntity() {
        return this.entity;
    }

    triggerApiOrRouteOrMvel(apiOrRouteOrMvelDataModel: string, payload: any, isMvelExecution: boolean, metadata?: APIMetadata): Observable<any> {
        if (apiOrRouteOrMvelDataModel && payload) {
            payload['dataModelId'] = this.dataModelId;
            payload['entityId'] = this.entityId;
            payload['entityName'] = this.dataModelName;
            let tmpFieldArr = [];
            if (metadata && metadata.dataModelFieldMap.size != 0) {
                tmpFieldArr = this.flattenDataModelField(metadata.dataModelFieldMap);
                // for (let [key, value] of metadata.dataModelFieldMap) {
                //     if (value.value) {
                //         let tmpField = {};
                //         tmpField[key+""] = value.value;
                //         tmpFieldArr.push(tmpField)
                //     }
                // }
                payload['pageFields'] = tmpFieldArr;
            }
            let obseravable: Observable<any> = null;

            if (isMvelExecution) {
                obseravable = this.evaluateMvel(apiOrRouteOrMvelDataModel, payload);
            } else {
                if (apiOrRouteOrMvelDataModel.startsWith('route:')) {
                    obseravable = this.executeRoute(apiOrRouteOrMvelDataModel.replace('route:', ''), payload);
                } else {
                    obseravable = this.executeApi(apiOrRouteOrMvelDataModel, payload);
                }
            }

            if (obseravable && metadata && metadata.isValid()) {
                return this.processResponseAsync(obseravable, metadata);
            } else {
                return obseravable;
            }
        }

        return null;
    }

    flattenDataModelField(dataModelFieldMap) {
        let tmpFieldArr = [];
        dataModelFieldMap.forEach((field: Field, key: string) => {
            if (field) {
                let tmpField = {};
                let flag = false;
                if (field["type"] && field["type"] === "MODEL" && field["listvalue"] && field["listvalue"].length > 0) {
                    let listVals = [];
                    for (let listValInd = 0; listValInd < field["listvalue"].length; listValInd++) {
                        const listvalueitem = field["listvalue"][listValInd];
                        if (listvalueitem && listvalueitem["fields"] && listvalueitem["fields"].length > 0) {
                            let tmpFieldArrForModel = this.flattenDataModelField(listvalueitem["fields"]);
                            listVals.push(tmpFieldArrForModel);
                            flag = true;
                        }
                    }
                    tmpField[key + ""] = listVals;
                } else if (field["type"] && field["type"] === "MODEL" && !field["listvalue"]) {
                    if (field["fields"] && field["fields"].length > 0) {
                        let tmpFieldArrForModel = this.flattenDataModelField(field["fields"]);
                        tmpField[key + ""] = tmpFieldArrForModel;
                        flag = true;
                    } else {
                        this.dataModelService.getDataModelByName(field.modelName).subscribe(
                            response => {
                                field['fields'] = response.fields
                                let tmpFieldArrForModel = this.flattenDataModelField(field["fields"]);
                                tmpField[key + ""] = tmpFieldArrForModel;
                                flag = true;
                            },
                            error => { }
                        );
                    }
                } else {
                    if (field && field["value"]) {
                        tmpField[key + ""] = field["value"];
                        flag = true;
                    }
                }
                if (flag) {
                    tmpFieldArr.push(tmpField);
                }
            }
        });
        return tmpFieldArr;

    }


    private executeApi(apiName: string, requestBody: any): Observable<any> {
        const subject: Subject<any> = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url: string = envData.baseURL + envData.flowAPI + envData.apiConfigBaseUrl + "execute/" + envData.apiName;;

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.post<any>(
                    url,
                    requestBody,
                    {
                        observe: 'response',
                        reportProgress: true,
                        headers: this.httpHeaders
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            subject.next(response.body);
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        subject.error(err);
                    }
                );
            }
        );

        return subject.asObservable();
    }

    private executeRoute(routeCode: string, requestBody: any): Observable<any> {
        const subject: Subject<any> = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url: string = envData.baseURL + envData.flowAPI + envData.routeBaseUrl + envData.routeUrl + routeCode;

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.post<any>(
                    url,
                    requestBody,
                    {
                        observe: 'response',
                        reportProgress: true,
                        headers: this.httpHeaders
                    }
                ).subscribe(
                    (response: HttpResponse<any>) => {
                        if (response.body) {
                            const responseBody = response.body;

                            if (responseBody['payload'] && responseBody['payload']['successMessage']) {
                                this.toastrService.success(responseBody['payload']['successMessage']);
                            } else if (responseBody['payload'] && responseBody['payload']['errorMessage']) {
                                this.toastrService.error(responseBody['payload']['errorMessage']);
                            }

                            subject.next(responseBody);
                        }
                    },
                    (err: HttpErrorResponse) => {
                        // All errors are handled in ErrorInterceptor, no further handling required
                        // Unless any specific action is to be taken on some error

                        subject.error(err);
                    }
                );
            }
        );

        return subject.asObservable();
    }

    evaluateMvel(entityName: string, payload: any): Observable<any> {
        const subject = new Subject<any>();
        const envData = this.environmentData.getEnvData();
        const url = envData.baseURL + envData.flowAPI + envData.entityurl + envData.evaluateMvel + entityName;

        this.authService.validateAuthToken().subscribe(
            (isValidToken: boolean) => {
                this.httpClient.post<any>(
                    url,
                    payload,
                    {
                        observe: 'response',
                        reportProgress: true,
                        withCredentials: true
                    }
                )
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            if (response.body) {
                                const payload = {
                                    'payload': {
                                        'fieldControl': response.body
                                    }
                                };
                                subject.next(payload);
                            }
                        },
                        (err: HttpErrorResponse) => {
                            // All errors are handled in ErrorInterceptor, no further handling required
                            // Unless any specific action is to be taken on some error

                            subject.error(err);
                        }
                    );
            }
        );

        return subject.asObservable();
    }

    private processResponseAsync(apiResponseObservable: Observable<any>, apiMetadata: APIMetadata): Observable<any> {
        const intermediateSubject: Subject<any> = new Subject<any>();

        if (apiResponseObservable && apiMetadata && apiMetadata.isValid()) {
            apiResponseObservable.subscribe(
                result => {
                    result = this.processResponse(result, apiMetadata);
                    intermediateSubject.next(result);
                }, (err: HttpErrorResponse) => {
                    intermediateSubject.error(err);
                }
            );
        } else {
            intermediateSubject.error('Invalid response');
        }

        return intermediateSubject.asObservable();
    }

    processResponse(apiResponse: any, apiMetadata: APIMetadata): any {
        if (apiResponse && apiMetadata && apiMetadata.isValid()) {
            const updatedFields = apiResponse['payload'] && apiResponse['payload']['updatedFields'] ? apiResponse['payload']['updatedFields'] : null;
            const fieldControl = apiResponse['payload'] && apiResponse['payload']['fieldControl'] ? apiResponse['payload']['fieldControl'] : null;

            if (updatedFields && Object.keys(updatedFields).length > 0) {
                for (const key of Object.keys(updatedFields)) {
                    if (key && updatedFields[key]) {
                        this.fieldOnChangeService.onChange(key, updatedFields[key], apiMetadata.formGroup);
                    }
                }

                apiResponse[APIService._IS_FORM_VALIDATION_NEEDED] = true;
            } else if (fieldControl && Object.keys(fieldControl).length > 0) {
                for (const key of Object.keys(fieldControl)) {
                    if (key && fieldControl[key] && Object.keys(fieldControl[key]).length > 0) {
                        const field = apiMetadata.dataModelFieldMap.get(key);

                        if (field) {
                            for (const action of Object.keys(fieldControl[key])) {
                                if (action && fieldControl[key][action]) {
                                    const actionResult = fieldControl[key][action]['result'];
                                    const actionMessage = fieldControl[key][action]['message'];

                                    if (action == FieldActions.ENABLE.toString()) {
                                        field.disable = !actionResult;
                                    } else if (action == FieldActions.SHOW.toString()) {
                                        field.hide = !actionResult;
                                    } else if (action == FieldActions.MANDATORY.toString()) {
                                        field.mandatory = actionResult;

                                        if (actionResult) {
                                            apiMetadata.formGroup.controls[field.name].setValidators(Validators.required);
                                        } else {
                                            apiMetadata.formGroup.controls[field.name].clearValidators();
                                        }
                                    } else if (action == FieldActions.ERROR.toString()) {
                                        if (actionResult && actionMessage) {
                                            apiMetadata.errorMap[key] = actionMessage;
                                        } else {
                                            delete apiMetadata.errorMap[key];
                                        }
                                    } else if (action == FieldActions.WARNING.toString()) {
                                        if (actionResult && actionMessage) {
                                            apiMetadata.warningMap[key] = actionMessage;
                                        } else {
                                            delete apiMetadata.warningMap[key];
                                        }
                                    } else if (action == FieldActions.VALUE.toString()) {
                                        this.fieldOnChangeService.onChange(key, actionResult, apiMetadata.formGroup);
                                    }
                                }
                            }
                        }

                    }
                }

                apiResponse[APIService._IS_FORM_VALIDATION_NEEDED] = true;
            }
        } else {
            console.error("Invalid API Metadata to process response");
        }

        return apiResponse;
    }
}

export class APIMetadata {
    formGroup: FormGroup;
    dataModelFieldMap: Map<String, Field>;
    errorMap: any;
    warningMap: any;

    constructor(formGroup: FormGroup, dataModelFieldMap: Map<String, Field>, errorMap: any, warningMap: any) {
        this.formGroup = formGroup;
        this.dataModelFieldMap = dataModelFieldMap;
        this.errorMap = errorMap;
        this.warningMap = warningMap;
    }

    isValid() {
        return this.formGroup && this.dataModelFieldMap
            && this.errorMap != null && this.errorMap != undefined
            && this.warningMap != null && this.warningMap != undefined;
    }
}